html, body {
  background-color: #fafafa;
}

.underline-animation-dark {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #092c1c;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after,
  &.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.underline-animation-light {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after,
  &.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
